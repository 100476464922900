import { useEffect, useState } from "react";

export default function useMediaQuery(query: string) {
    const [match, setMatch] = useState(false);

    useEffect(() => {
        const updateMatch = (e: MediaQueryListEvent) => setMatch(e.matches);
        const mql = window.matchMedia(query);
        setMatch(mql.matches);
        mql.addEventListener("change", updateMatch);
        return () => {
            mql.removeEventListener("change", updateMatch);
        };
    }, [query]);

    return match;
}
