import { observer } from "mobx-react";
import Pica from "pica/dist/pica.js";
import React, { useCallback, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import uuid from "uuid";
import useDefinedContext from "../../hooks/useDefinedContext";
import RootModelContext from "../../models/RootModel";
import StorageModel from "../../models/StorageModel";
import css from "./ImageUploader.module.css";

export interface ImageUploaderProps extends RouteComponentProps<{}> {
}

interface ResizeConfig {
    srcLeft: number;
    srcTop: number;
    srcWidth: number;
    srcHeight: number;
    destLeft: number;
    destTop: number;
    destWidth: number;
    destHeight: number;
}

const configs: {[key: string]: Array<[(width: number, height: number) => ResizeConfig, string]>} = {
    "place": [
        [(width, height) => downscale(width, height, 1200), "place"],
        [(width, height) => downscale(width, height, 250, 200), "place_preview"],
    ],
    "story": [
        [(width, height) => downscale(width, height, 2000, 600), "story"],
        [(width, height) => downscale(width, height, 270, 112), "story_preview"],
    ],
};

function resizeAndUpload(file: File, configName: string, model: StorageModel) {
    const ext = getExtension(file.name);
    const config = configs[configName];
    const scales = config.map(c => c[0]);
    const name = `${uuid.v4()}.${ext}`;
    console.log(`[${file.name}, ${name}],`);
    return resizeImage(file, ext === "png", scales)
        .then((blobs) => {
            return Promise.all(
                config.map((c, i) => {
                    const folder = c[1] as keyof typeof model.FOLDERS;
                    console.log(`Uploading ${name} to ${folder}`);
                    return model.uploadImage(model.FOLDERS[folder], name, blobs[i], file.type);
                }),
            );
        })
        .then(() => name);
}

const ImageUploader: React.FC<ImageUploaderProps> = observer(() => {
    const selectRef = useRef<HTMLSelectElement>(null);
    const fileRef = useRef<HTMLInputElement>(null);
    const rootModel = useDefinedContext(RootModelContext);
    const model = rootModel.storageModel;
    const [fileName, setFileName] = useState<boolean | string | undefined>(undefined);
    const upload = useCallback(() => {
        if (fileRef.current && selectRef.current) {
            const configName = selectRef.current.value as keyof typeof configs;
            if (fileRef.current.files && fileRef.current.files.length) {
                setFileName(true);
                const files = Array.from(fileRef.current.files)
                    .filter(file => {
                        const ext = getExtension(file.name);
                        return ["bmp", "jpg", "jpeg", "png"].indexOf(ext) !== -1 && file.size <= SIZE_LIMIT;
                    });
                console.log(files.map(f => f.name));
                const result: Array<[string, string]> = [];
                let promise = Promise.resolve();
                for (const file of files) {
                    promise.then(() => {
                        resizeAndUpload(file, String(configName), model)
                            .then((name) => {
                                result.push([file.name, name]);
                            });
                    });
                }
                promise.then(() => {
                    console.log(JSON.stringify(result, undefined, 2));
                    setFileName("done, see console");
                })
            } else {
                alert("Select an image to upload first");
            }
        }
    }, [selectRef, fileRef, setFileName, model]);
    return (
        <div className={css.main}>
            <select ref={selectRef}>
                <option value={"place"}>Фотография места (1200px * ?, 250px * 200px)</option>
                <option value={"story"}>Главная фотография истории (2000px * 600px, 270px * 112px)</option>
            </select>
            <input type="file" ref={fileRef} multiple={true} />
            <button onClick={upload}>Upload</button>
            {fileName !== undefined && (
                fileName === true ?
                    <h3 style={{color: "blue"}}>Loading...</h3> :
                    fileName === false ?
                        <h3 style={{color: "red"}}>Failed - see console</h3> :
                        fileName && <h3 style={{color: "green"}}>Uploaded as: {fileName}</h3>
            )}
        </div>
    );
});

const SIZE_LIMIT = 1024 * 1024 * 100; // 100MB;

// Resize image if needed
//
function resizeImage(file: File, useAlpha: boolean, scalers: Array<(width: number, height: number) => ResizeConfig>): Promise<Array<Blob>> {
    const pica = new Pica();

    return new Promise((resolve, reject) => {
        // Next tick
        setTimeout(() => {
            const img = new Image();

            img.onload = async () => {
                const source = document.createElement("canvas");
                const dest = document.createElement("canvas");
                const result: Array<Blob> = [];
                for (const scaler of scalers) {
                    const config = scaler(img.width, img.height);
                    source.width = config.srcWidth;
                    source.height = config.srcHeight;
                    dest.width = config.destWidth;
                    dest.height = config.destHeight;

                    source.getContext("2d")!
                        .drawImage(
                            img,
                            config.srcLeft,
                            config.srcTop,
                            config.srcWidth,
                            config.srcHeight,
                            0,
                            0,
                            config.srcWidth,
                            config.srcHeight,
                        );

                    await pica.resize(source, dest, {alpha: useAlpha})
                        .then(() => pica.toBlob(dest, file.type))
                        .then((blob) => {
                            if (blob) {
                                result.push(blob);
                            } else {
                                reject(new Error("Resize failed"));
                            }
                        });
                }
                resolve(result);
            };

            img.onerror = () => {
                reject(new Error("Error loading image to an Image object"));
            };

            img.src = window.URL.createObjectURL(file);
        }, 0);
    });
}

function getExtension(fileName: string) {
    const parts = fileName.split(".");
    return parts[parts.length - 1] || "";
}

function downscale(width: number, height: number, maxWidth?: number, maxHeight?: number) {
    let factor = 1;
    if (maxWidth !== undefined) {
        factor = maxWidth / width;
    }
    if (maxHeight !== undefined) {
        factor = Math.max(maxHeight / height, factor);
    }
    let srcLeft = 0;
    let srcTop = 0;
    const scaledWidth = width * factor;
    const scaledHeight = height * factor;
    if (maxWidth !== undefined && scaledWidth > maxWidth) {
        const widthDiff = (scaledWidth - maxWidth) / factor;
        srcLeft = widthDiff / 2;
        width -= widthDiff;
    }
    if (maxHeight !== undefined && scaledHeight > maxHeight) {
        const heightDiff = (scaledHeight - maxHeight) / factor;
        srcTop = heightDiff / 2;
        height -= heightDiff;
    }

    return {
        srcLeft,
        srcTop,
        srcWidth: width,
        srcHeight: height,
        destLeft: 0,
        destTop: 0,
        destWidth: Math.min(scaledWidth, maxWidth || Infinity),
        destHeight: Math.min(scaledHeight, maxHeight || Infinity),
    } as ResizeConfig;
}

export default ImageUploader;
