import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {autorun, IReactionDisposer, when} from "mobx";
import {Instance} from "mobx-state-tree";
import {createTransformer} from "mobx-utils";
import React, {useEffect} from "react";
import useDefinedContext from "../../hooks/useDefinedContext";
import RootModelContext, {Experience} from "../../models/RootModel";
import css from "./MapView.module.css";
import {FaRegStar, FaStar, FaStarHalfAlt} from "react-icons/fa";
import {Card, CardBody, CardSubtitle, CardTitle} from "reactstrap";
import {observer} from "mobx-react";

export interface MapProps {

}

mapboxgl.accessToken = "pk.eyJ1IjoiZ3Jhc3Nob3BwZXJnbiIsImEiOiJjazBrdTQxZDUwamZvM2R0a3U3dXBsczF3In0.OIWl5Wj6foHoF9PnGwXV6w";

const MapView: React.FC<MapProps> = observer(() => {
    const rootModel = useDefinedContext(RootModelContext);
    const mapModel = rootModel.mapModel;
    useEffect(() => {
        const map = mapModel.createMap({
            container: "map", // container id
            trackResize: true,
            style: "mapbox://styles/mapbox/light-v9", // stylesheet location
        });
        map.addControl(new mapboxgl.NavigationControl());
        map.addControl(new mapboxgl.GeolocateControl());
        map.addControl(new mapboxgl.ScaleControl());
        map.addControl(new mapboxgl.FullscreenControl());
        setTimeout(() => {
            window.dispatchEvent(new CustomEvent("resize"));
        }, 0);

        const getMarkerInstance = createTransformer((experience: Instance<typeof Experience>) => {
            const el = document.createElement("div");
            el.className = css.marker;
            el.addEventListener("mouseenter", () => {
                rootModel.setHoveredMapPlace(experience);
            });
            el.addEventListener("mouseleave", () => {
                rootModel.setHoveredMapPlace(undefined);
            });
            el.addEventListener("click", () => {
                if (experience.photos.length && experience.photos[0]) {
                    rootModel.storageModel.getPlacePreview(experience.photos[0])
                        .then((url) => {
                            if (url) {
                                const imgElement = document.getElementById(`experience-photo-popup-${experience.id}`);
                                if (imgElement) {
                                    imgElement.setAttribute("src", url);
                                }
                            }
                        })
                }
            }, {once: true});
            const marker = new mapboxgl.Marker({
                element: el,
                anchor: "bottom",
                draggable: false,
                offset: [0, 0],
            });
            marker.setLngLat([experience.place.longitude, experience.place.latitude]);
            const popup = new mapboxgl.Popup({ offset: 25,  className: 'card text-center'})
                .setHTML(`
                <div style="width: 250px; height: 165px;">
                    <img src="" class="card-img" alt="${experience.name}" id="experience-photo-popup-${experience.id}">
                    <div class="card-img-overlay">
                        <h5 class="card-title"><a href="#">${experience.name}</a></h5>
                        <p class="card-text">
                            <small class="text-muted"><a>13 October 2019</a></small> | 
                        <small class="text-muted"><a>by @savorli</a></small>
                        </p>
                    </div>
                </div>
            `)
                .setMaxWidth("250px");

            marker.setPopup(popup);

            return marker;
        });
        const renderedMarkers: Array<mapboxgl.Marker> = [];
        let autoRunDisposers: Array<IReactionDisposer> = [];

        map.once("load", () => {
            autoRunDisposers.push(autorun(() => {
                renderedMarkers.forEach(marker => {
                    marker.remove();
                });
                renderedMarkers.length = 0;
                rootModel.foundPlaces.forEach(experience => {
                    renderedMarkers.push(
                        getMarkerInstance(experience)
                            .addTo(map),
                    );
                });
            }));
            autoRunDisposers.push(autorun(() => {
                rootModel.foundPlaces.forEach(experience => {
                    const hovered = (!rootModel.hoveredStory && !rootModel.hoveredMapPlace) ||
                        (rootModel.hoveredStory && rootModel.hoveredStory.experiences.indexOf(experience) >= 0) ||
                        (rootModel.hoveredMapPlace === experience);
                    const el = getMarkerInstance(experience)
                        .getElement();
                    el.classList.toggle(css.hovered, hovered);
                });
            }));
        });

        return () => {
            mapModel.disposeMap();
            autoRunDisposers.forEach(disposer => disposer());
        };
    }, [mapModel, rootModel]);


    return (
        <div className={css.mapBox} id="map">

            <div id="pinMenu" className={css.pinMenu} style={{display: "none"}}>
                <button className={css.closePinMenu} type="button" aria-label="Close popup">×</button>
                <Card className={css.pinMenuCard}>
                    <CardTitle className={css.pinMenuTitle}> Paris </CardTitle>
                    <CardSubtitle className={css.pinMenuSubTitle}> mentioned in 7 stories </CardSubtitle>
                    <div className={css.scrollable}>
                        <CardBody className={css.popupGrid}>
                            <a aria-haspopup="true" href="#" className={css.userPreview} aria-expanded="false">
                                <img
                                    src="https://scontent-arn2-1.xx.fbcdn.net/v/t1.0-9/16427795_1849490815309013_4412486175841850107_n.jpg?_nc_cat=100&amp;_nc_oc=AQnLnlBmFacf5CcMrqHqFV4Ixc5psEfF3keRkYC9IaI2rFYMGyxdKGlaEIl3FWW1UyA&amp;_nc_ht=scontent-arn2-1.xx&amp;oh=513a1a59ff70f3b4f5044e18b157de81&amp;oe=5DCB4D8C"
                                    className="iconImg media-object"/>
                            </a>
                            <div className={css.storyPlacePreview}>
                                <span
                                    className={css.placeStars}> <FaStar/><FaStar/><FaStarHalfAlt/><FaRegStar/><FaRegStar/> </span>
                                <p className={css.readStory}><a href="#"> Read more in "Tour de Mont Blanc"</a></p>
                            </div>
                        </CardBody>
                        <CardBody className={css.popupGrid}>
                            <a aria-haspopup="true" href="#" className={css.userPreview} aria-expanded="false">
                                <img
                                    src="https://scontent-arn2-1.xx.fbcdn.net/v/t1.0-9/16427795_1849490815309013_4412486175841850107_n.jpg?_nc_cat=100&amp;_nc_oc=AQnLnlBmFacf5CcMrqHqFV4Ixc5psEfF3keRkYC9IaI2rFYMGyxdKGlaEIl3FWW1UyA&amp;_nc_ht=scontent-arn2-1.xx&amp;oh=513a1a59ff70f3b4f5044e18b157de81&amp;oe=5DCB4D8C"
                                    className="iconImg media-object"/>
                            </a>
                            <div className={css.storyPlacePreview}>
                                <span
                                    className={css.placeStars}> <FaStar/><FaStar/><FaStarHalfAlt/><FaRegStar/><FaRegStar/> </span>
                                <p className={css.readStory}><a href="#"> Read more in "Tour de Mont Blanc"</a></p>
                            </div>
                        </CardBody>
                        <CardBody className={css.popupGrid}>
                            <a aria-haspopup="true" href="#" className={css.userPreview} aria-expanded="false">
                                <img
                                    src="https://scontent-arn2-1.xx.fbcdn.net/v/t1.0-9/16427795_1849490815309013_4412486175841850107_n.jpg?_nc_cat=100&amp;_nc_oc=AQnLnlBmFacf5CcMrqHqFV4Ixc5psEfF3keRkYC9IaI2rFYMGyxdKGlaEIl3FWW1UyA&amp;_nc_ht=scontent-arn2-1.xx&amp;oh=513a1a59ff70f3b4f5044e18b157de81&amp;oe=5DCB4D8C"
                                    className="iconImg media-object"/>
                            </a>
                            <div className={css.storyPlacePreview}>
                                <span
                                    className={css.placeStars}> <FaStar/><FaStar/><FaStarHalfAlt/><FaRegStar/><FaRegStar/> </span>
                                <p className={css.readStory}><a href="#"> Read more in "Tour de Mont Blanc"</a></p>
                            </div>
                        </CardBody>
                        <CardBody className={css.popupGrid}>
                            <a aria-haspopup="true" href="#" className={css.userPreview} aria-expanded="false">
                                <img
                                    src="https://scontent-arn2-1.xx.fbcdn.net/v/t1.0-9/16427795_1849490815309013_4412486175841850107_n.jpg?_nc_cat=100&amp;_nc_oc=AQnLnlBmFacf5CcMrqHqFV4Ixc5psEfF3keRkYC9IaI2rFYMGyxdKGlaEIl3FWW1UyA&amp;_nc_ht=scontent-arn2-1.xx&amp;oh=513a1a59ff70f3b4f5044e18b157de81&amp;oe=5DCB4D8C"
                                    className="iconImg media-object"/>
                            </a>
                            <div className={css.storyPlacePreview}>
                                <span
                                    className={css.placeStars}> <FaStar/><FaStar/><FaStarHalfAlt/><FaRegStar/><FaRegStar/> </span>
                                <p className={css.readStory}><a href="#"> Read more in "Tour de Mont Blanc"</a></p>
                            </div>
                        </CardBody>
                        <CardBody className={css.popupGrid}>
                            <a aria-haspopup="true" href="#" className={css.userPreview} aria-expanded="false">
                                <img
                                    src="https://scontent-arn2-1.xx.fbcdn.net/v/t1.0-9/16427795_1849490815309013_4412486175841850107_n.jpg?_nc_cat=100&amp;_nc_oc=AQnLnlBmFacf5CcMrqHqFV4Ixc5psEfF3keRkYC9IaI2rFYMGyxdKGlaEIl3FWW1UyA&amp;_nc_ht=scontent-arn2-1.xx&amp;oh=513a1a59ff70f3b4f5044e18b157de81&amp;oe=5DCB4D8C"
                                    className="iconImg media-object"/>
                            </a>
                            <div className={css.storyPlacePreview}>
                                <span
                                    className={css.placeStars}> <FaStar/><FaStar/><FaStarHalfAlt/><FaRegStar/><FaRegStar/> </span>
                                <p className={css.readStory}><a href="#"> Read more in "Tour de Mont Blanc"</a></p>
                            </div>
                        </CardBody>
                        <CardBody className={css.popupGrid}>
                            <a aria-haspopup="true" href="#" className={css.userPreview} aria-expanded="false">
                                <img
                                    src="https://scontent-arn2-1.xx.fbcdn.net/v/t1.0-9/16427795_1849490815309013_4412486175841850107_n.jpg?_nc_cat=100&amp;_nc_oc=AQnLnlBmFacf5CcMrqHqFV4Ixc5psEfF3keRkYC9IaI2rFYMGyxdKGlaEIl3FWW1UyA&amp;_nc_ht=scontent-arn2-1.xx&amp;oh=513a1a59ff70f3b4f5044e18b157de81&amp;oe=5DCB4D8C"
                                    className="iconImg media-object"/>
                            </a>
                            <div className={css.storyPlacePreview}>
                                <span
                                    className={css.placeStars}> <FaStar/><FaStar/><FaStarHalfAlt/><FaRegStar/><FaRegStar/> </span>
                                <p className={css.readStory}><a href="#"> Read more in "Tour de Mont Blanc"</a></p>
                            </div>
                        </CardBody>
                        <CardBody className={css.popupGrid}>
                            <a aria-haspopup="true" href="#" className={css.userPreview} aria-expanded="false">
                                <img
                                    src="https://scontent-arn2-1.xx.fbcdn.net/v/t1.0-9/16427795_1849490815309013_4412486175841850107_n.jpg?_nc_cat=100&amp;_nc_oc=AQnLnlBmFacf5CcMrqHqFV4Ixc5psEfF3keRkYC9IaI2rFYMGyxdKGlaEIl3FWW1UyA&amp;_nc_ht=scontent-arn2-1.xx&amp;oh=513a1a59ff70f3b4f5044e18b157de81&amp;oe=5DCB4D8C"
                                    className="iconImg media-object"/>
                            </a>
                            <div className={css.storyPlacePreview}>
                                <span
                                    className={css.placeStars}> <FaStar/><FaStar/><FaStarHalfAlt/><FaRegStar/><FaRegStar/> </span>
                                <p className={css.readStory}><a href="#"> Read more in "Tour de Mont Blanc"</a></p>
                            </div>
                        </CardBody>
                    </div>

                    <CardSubtitle className={css.pinMenuShowAll}> <a href="#">Show all stories here</a> </CardSubtitle>
                </Card>
            </div>
        </div>
    );
});

export default MapView;
