import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import About from "./about/About";
import ImageUploader from "./imageUploader/ImageUploader";
import Main from "./main/Main";
import Places from "./places/Places";
import Stories from "./stories/Stories";
import Users from "./users/Users";

const Routes: React.FC = () => {
    return (
        <BrowserRouter>
            <Route path="/" exact component={Main} />
            <Route path="/about" component={About} />
            <Route path="/stories/:id" component={Stories} />
            <Route path="/places/:id" component={Places} />
            <Route path="/users/:id" component={Users} />
            <Route path="/__upload" component={ImageUploader} />
        </BrowserRouter>
    );
};

export default Routes;
