import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import useDefinedContext from "../../hooks/useDefinedContext";
import RootModelContext from "../../models/RootModel";

export interface AboutProps extends RouteComponentProps<{}> {
}

const About: React.FC<AboutProps> = observer((props: AboutProps) => {
    const model = useDefinedContext(RootModelContext);
    return (
        <>
            <div>HI! {model.user && model.user.name}
                <p>This should be about project page</p>
            </div>
            <Link to="/">Go back to main</Link>
        </>
    );
});

export default About;
