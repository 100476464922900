import "bootstrap/dist/css/bootstrap.min.css";
import firebase from "firebase/app";
import "firebase/storage";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useToggle from "./hooks/useToggle";
import MapModel from "./models/MapModel";
import RootModelContext, { RootModel } from "./models/RootModel";
import StorageModel from "./models/StorageModel";
import Routes from "./routes/Routes";
import * as serviceWorker from "./serviceWorker";

const app = firebase.initializeApp({
    apiKey: "AIzaSyCvgYQFtP1oYVljjiK118qJmp2vulVY7qY",
    authDomain: "travel-maps-club.firebaseapp.com",
    databaseURL: "https://travel-maps-club.firebaseio.com",
    projectId: "travel-maps-club",
    storageBucket: "travel-maps-club.appspot.com",
    messagingSenderId: "256207812439",
    appId: "1:256207812439:web:7110dfa5092d9eb1ff3012",
}, "main");

const model = RootModel.create({
    user: "1",

    users: [
        {
            id: "1",
            name: "Olga",
            lastName: "Saukh",
            username: "savorli",
            pic: "https://scontent-arn2-1.xx.fbcdn.net/v/t1.0-9/16427795_1849490815309013_4412486175841850107_n.jpg?_nc_cat=100&_nc_oc=AQnLnlBmFacf5CcMrqHqFV4Ixc5psEfF3keRkYC9IaI2rFYMGyxdKGlaEIl3FWW1UyA&_nc_ht=scontent-arn2-1.xx&oh=513a1a59ff70f3b4f5044e18b157de81&oe=5DCB4D8C",
            email: "olsaukh@gmail.com",
            experiences: [
                "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28",
                "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43",
            ],
        },
        {
            id: "2",
            name: "Anton",
            lastName: "Saukh",
            username: "grasshoppergn",
            pic: "https://media-exp1.licdn.com/dms/image/C5603AQFvHZmZoYl-AA/profile-displayphoto-shrink_200_200/0?e=1576713600&v=beta&t=gQ7OXmCFzuXMsYhNZulxWMytNxWyMI-LAEmZksfX-B4",
            email: "grasshoppergn@gmail.com",
            experiences: [
                "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12",
                "44", "45", "46", "47", "48", "49", "50",
            ],
        },
    ],

    places: [
        {
            id: "1",
            name: "Chamonix",
            longitude: 6.867730,
            latitude: 45.921436,
        },
        {
            id: "2",
            name: "Aiguille du Midi",
            longitude: 6.887189,
            latitude: 45.879272,
        },
        {
            id: "3",
            name: "Les Houches",
            longitude: 6.778975,
            latitude: 45.872418,
        },
        {
            id: "4",
            name: "Les Contamines-Montjoie",
            longitude: 6.715548,
            latitude: 45.792591,
        },
        {
            id: "5",
            name: "Refuge du Bonhomme",
            longitude: 6.717392,
            latitude: 45.722133,
        },
        {
            id: "6",
            name: "Refugio Elisatetta",
            longitude: 6.837352,
            latitude: 45.766994,
        },
        {
            id: "7",
            name: "Courmayeur",
            longitude: 6.973829,
            latitude: 45.789865,
        },
        {
            id: "8",
            name: "Chalet Val Ferret",
            longitude: 7.053025,
            latitude: 45.871016,
        },
        {
            id: "9",
            name: "Champex",
            longitude: 7.113717,
            latitude: 46.028123,
        },
        {
            id: "10",
            name: "Refuge de Balme",
            longitude: 6.971108,
            latitude: 46.026232,
        },
        {
            id: "11",
            name: "Annecy",
            longitude: 6.126154,
            latitude: 45.898844,
        },
        {
            id: "12",
            name: "Geneve",
            longitude: 6.148572,
            latitude: 46.207196,
        },
        {
            id: "13",
            name: "Oslo",
            longitude: 10.753443,
            latitude: 59.906411,
        },
        {
            id: "14",
            name: "Bergen",
            longitude: 5.324385,
            latitude: 60.395288,
        },
        {
            id: "15",
            name: "Alesund",
            longitude: 6.153961,
            latitude: 62.471426,
        },
        {
            id: "16",
            name: "Trondheim",
            longitude: 10.401691,
            latitude: 63.429370,
        },
        {
            id: "17",
            name: "Tromsø",
            longitude: 18.956738,
            latitude: 69.647467,
        },
        {
            id: "18",
            name: "Sagan-Zaba",
            longitude: 106.476754,
            latitude: 52.683003,
        },
        {
            id: "19",
            name: "Old marble quarry",
            longitude: 106.121267,
            latitude: 52.586355,
        },
        {
            id: "20",
            name: "Buguldeyka",
            longitude: 106.061854,
            latitude: 52.530867,
        },
        {
            id: "21",
            name: "Bolshie Koty",
            longitude: 105.072864,
            latitude: 51.903590,
        },
        {
            id: "22",
            name: "Lystvyanka in March",
            longitude: 104.868607,
            latitude: 51.849950,
        },
        {
            id: "23",
            name: "Cherskiy peak",
            longitude: 103.626496,
            latitude: 51.515945,
        },
        {
            id: "24",
            name: "Sheregesh in Winter",
            longitude: 87.931945,
            latitude: 52.954791,
        },
        {
            id: "25",
            name: "Arshan",
            longitude: 102.423054,
            latitude: 51.917604,
        },
        {
            id: "26",
            name: "Tanchoy",
            longitude: 105.167188,
            latitude: 51.551699,
        },
        {
            id: "27",
            name: "Chivirkuy",
            longitude: 108.970470,
            latitude: 53.654135,
        },
        {
            id: "28",
            name: "Altay mountains, Shavlinskiye Ozora",
            longitude: 87.410552,
            latitude: 50.106452,
        },
        {
            id: "29",
            name: "Odense",
            longitude: 10.389033,
            latitude: 55.395911,
        },
        {
            id: "30",
            name: "Hillerød",
            longitude: 12.296875,
            latitude: 55.936164,
        },
        {
            id: "31",
            name: "Helsingør & Kronborg",
            longitude: 12.621691,
            latitude: 56.037260,
        },
        {
            id: "32",
            name: "Our favourite beach",
            longitude: 11.708779,
            latitude: 55.950245,
        },
        {
            id: "33",
            name: "Dragør",
            longitude: 12.674520,
            latitude: 55.594033,
        },
        {
            id: "34",
            name: "GeoCenter Møns Klint",
            longitude: 12.549848,
            latitude: 54.966710,
        },
        {
            id: "35",
            name: "Lund",
            longitude: 13.193648,
            latitude: 55.704412,
        },
        {
            id: "36",
            name: "Crabi, Ao Nang Beach",
            longitude: 98.822519,
            latitude: 8.030929,
        },
        {
            id: "37",
            name: "Maya Bay",
            longitude: 98.767043,
            latitude: 7.678176,
        },
        {
            id: "38",
            name: "Chicken Island",
            longitude: 98.803509,
            latitude: 7.953121,
        },
        {
            id: "39",
            name: "Similan Islands",
            longitude: 97.648372,
            latitude: 8.649859,
        },
        {
            id: "40",
            name: "Bangkok",
            longitude: 100.534149,
            latitude: 13.745990,
        },
        {
            id: "41",
            name: "Kuala Lumpur",
            longitude: 101.714099,
            latitude: 3.156224,
        },
        {
            id: "42",
            name: "Perhentian Islands",
            longitude: 102.717928,
            latitude: 5.904176,
        },
        {
            id: "43",
            name: "Redang Island",
            longitude: 102.999957,
            latitude: 5.754774,
        },
        {
            id: "44",
            name: "Odense",
            longitude: 10.389033,
            latitude: 55.395911,
        },
        {
            id: "45",
            name: "Kongens Have",
            longitude: 12.580261,
            latitude: 55.684926,
        },
    ],

    experiences: [
        {
            id: "1",
            place: "1",
            name: "Chamonix",
            photos: ["90dd6671-1ef4-4197-b9d7-57157634caaa.jpg", "53c67a2b-ddeb-45d0-8216-f3fb5164a017.jpg"],
            text: "This is a small french village. This place was a start for our hiking tour.",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "2",
            place: "2",
            name: "Aiguille du Midi",
            photos: ["78b65929-c1b1-464b-b7bc-d466db8e7025.jpg", "faaecc70-cca2-4bf1-8757-e8674d78be61.jpg", "97930700-712c-42ae-8693-aebce6c7c9eb.jpg"],
            text: "Very nice and beautiful viewpoint. Definitely recommend to visit (better early in the morning)",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "3",
            place: "3",
            name: "Les Houches",
            photos: ["cd398581-519f-4e6d-9491-50bed1fd0995.jpg"],
            text: "Our start point - Les Houches. We cheated, took the cabin Bellevue to avoid first ascent",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "4",
            place: "4",
            name: "Les Contamines-Montjoie",
            photos: ["a0bd1e11-0634-4a39-93bc-64148bf4f786.jpg"],
            text: "First day for us was not very hard, but really nice. Note that walk from center of the village to the camping will take almost an hour.",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "5",
            place: "5",
            name: "Refuge du Bonhomme",
            photos: ["b18803c2-bf6a-4e7e-829f-dab2800897fa.jpg", "b99ae22a-098f-4c39-9311-1214f5f239a4.jpg"],
            text: "The highest place to spent a night on the route. Very nice view. ",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "6",
            place: "6",
            name: "Refugio Elisatetta",
            photos: [],
            text: "Cozy and nice refuge, they serve very nice dinner there, the desert was awesome.",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "7",
            place: "7",
            name: "Courmayeur",
            photos: ["95dc1593-a12c-4d5f-806b-a1be9b53c542.jpg", "34390100-fb71-4ae2-94ff-72ba919ead02.jpg"],
            text: "Cozy village, we used laundry there, had some rest, had dinner in a restaurant.",
            stars: 5,
            isPublic: true,
        },
        {
            id: "8",
            place: "8",
            name: "Chalet Val Ferret",
            photos: [],
            text: "Very nice and historical chalet. Nice food, bar with beers, cozy rooms.",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "9",
            place: "9",
            name: "Champex",
            photos: ["4934274a-ff57-4528-8632-1a690b0d21e2.jpg"],
            text: "Camping is on the side on the road, crowded and without any food. But lake is really beautiful and there were a lot of restaurants.",
            stars: 4,
            isPublic: true,
        },
        {
            id: "10",
            place: "10",
            name: "Refuge de Balme",
            photos: ["2d31fed3-8e0c-4081-82bc-d82fa8275845.jpg"],
            text: "Our last night in refuge. We stayed in tent near the refuge. Note, that for those who don't stay in refuge they don't serve dinner.",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "11",
            place: "11",
            name: "Annecy",
            photos: ["966e1977-2839-447d-8df5-f108a075e300.jpg", "65722dad-8cdb-45a6-b3b6-129455644461.jpg", "86604476-1188-4801-987e-1f0f03ff4129.jpg", "9a29ac79-4a6a-450b-9020-57bf1ec38416.jpg"],
            text: "Very nice and cozy town, beach on the lake, historical center with nice food. ",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "12",
            place: "12",
            name: "Geneve",
            photos: ["25d0a517-89d9-4965-9048-52398bff3825.jpg", "e072f4e8-44b2-4fc6-a170-b47669283d13.jpg", "a75c50a8-b97c-4361-8a4d-f0f260a5d095.jpg"],
            text: "City where we started and finished this trip. ",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "13",
            place: "13",
            name: "Oslo",
            photos: ["e9a21e20-dcb8-4b5c-9e8f-14f8d165f18b.jpg", "6fcc5089-625f-40eb-8770-471bb450d62a.jpg", "c24faef3-6516-4269-9a47-f7b347a709cd.jpg"],
            text: "Nice and big Olso has many parks and experiences to eat and to walk.",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "14",
            place: "14",
            name: "Bergen",
            photos: ["dedd396e-5b3f-4f1d-8556-b1f7e53ef5d9.jpg", "77777392-c6d7-4556-b6ba-2ba9626aa5eb.jpg", "66531a6e-ab1b-4a46-b3fa-d242af8028c7.jpg"],
            text: "Very cosy and very sunny (we got those all two days when it is not raining during the year)",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "15",
            place: "15",
            name: "Alesund",
            photos: ["0f663df1-a28a-4bee-8826-3d0f2d85474b.jpg", "4f4e9d6d-7416-4dd0-8952-492390b4bf3f.jpg"],
            text: "Awesome architecture. Very nice view point",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "16",
            place: "16",
            name: "Trondheim",
            photos: ["ae5215a1-d3b5-4e72-a1c1-305a3af86683.jpg", "e0002e17-0017-4e7c-b591-6b9d87ac5531.jpg", "fd1611a1-1edf-4ce3-b9b9-1ad67969c359.jpg"],
            text: "In this city sunrize was coming right to sunset, which also mean that light was perfect for taking pictures.",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "17",
            place: "17",
            name: "Tromsø",
            photos: ["750e8ee5-8c47-44fd-9d59-4d02cad891ea.jpg", "862dee5a-6e35-4ce4-85d4-ac93097d98f7.jpg", "2c5b2b0f-a350-41bf-b79f-4d8df488a0de.jpg", "a6c6c79c-9e91-4e8a-abe1-84f715262f05.jpg"],
            text: "Whales and northern lights - is the reason to visit this city over and over again!",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "18",
            place: "18",
            name: "Sagan-Zaba",
            photos: ["2a99acb2-2637-4d5b-b341-97827906894e.jpg"],
            text: "Awesome place with huge energetic. Don't sleep here.",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "19",
            place: "19",
            name: "Old marble quarry",
            photos: ["fc517dc9-b911-4d33-9c66-6046a33a66c4.jpg"],
            text: "Take insta-pictures there",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "20",
            place: "20",
            name: "Buguldeyka",
            photos: ["0bc36933-4255-42f2-aeb2-2042331c6be5.jpg", "ffa80d2e-cf97-4b52-a4f1-35baea8fbe0a.jpg"],
            text: "You can stay here over night, enjoy beaches and steppes.",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "21",
            place: "21",
            name: "Bolshie Koty",
            photos: ["82432307-ff4c-4b5a-a4c7-b5707a477e04.jpg", "8c1c86f6-d4bf-4efc-aeca-d9e6d03ff8da.jpg"],
            text: "Small and cozy village on a Baikal shore.",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "22",
            place: "22",
            name: "Lystvyanka in March",
            photos: ["d1355cad-068c-4141-8fac-0cd7e27236d7.jpg", "dc70ae7a-801b-47a4-be19-545238b8ced9.jpg"],
            text: "In March it os something special. Look at this ice!",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "23",
            place: "23",
            name: "Cherskiy peak",
            photos: ["a4da6300-0c61-41ee-bd76-6142cf2f3f24.jpg", "63b85f94-9a11-40bd-a157-5db1cdea2f11.jpg", "8d56c26c-89e6-4ce0-a94a-5acee80cddf3.jpg"],
            text: "Popular and very beautiful trekking route",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "24",
            place: "24",
            name: "Sheregesh in Winter",
            photos: ["ab53748f-841c-45fe-95e8-a2c439570c1f.jpg", "7f4f6e20-bed5-450b-81ee-4101ced08c5b.jpg"],
            text: "Mountain ski resort, I believe it is the best in Russia.",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "25",
            place: "25",
            name: "Arshan",
            photos: ["d1b81dce-29c6-4372-adbe-15e32310923d.jpg", "57b78a96-4215-42f4-9fb8-94a21e9f3c55.jpg"],
            text: "Small village from which a lot of mountain route starts.",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "26",
            place: "26",
            name: "Tanchoy",
            photos: ["bcc8e471-98fd-4342-a517-ed38be2b2946.jpg", "c63d76f2-b48a-4dec-8400-b1a9d326d635.jpg"],
            text: "Very nice aria to take a walk",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "27",
            place: "27",
            name: "Chivirkuy",
            photos: ["24a486a8-029c-42f6-9f19-7c233db0df0b.jpg", "356feeb7-8615-4136-8364-4e27d03e1547.jpg"],
            text: "Place where you can actually swim during the summer",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "28",
            place: "28",
            name: "Altay mountains, Shavlinskiye Ozora",
            photos: ["a514500f-d033-4c2c-bd19-9a5ba479d2f1.jpg", "194f2808-55c3-4c78-93a1-094543de3291.jpg"],
            text: "Awesome place, many trekking routes starts and finishes here",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "29",
            place: "29",
            name: "Odense",
            photos: ["2f999f1e-75d0-4b67-bf9d-06b0be2dd807.jpg"],
            text: "",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "30",
            place: "30",
            name: "Hillerød",
            photos: ["ae7a3c4c-2eec-4135-a1db-cba9b37e73d5.jpg"],
            text: "",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "31",
            place: "31",
            name: "Helsingør & Kronborg",
            photos: ["12cdafd7-515f-44d7-bb58-b7ffdf8bce9f.jpg", "b9b07211-e3db-4a63-abaa-e01c5845e340.jpg"],
            text: "",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "32",
            place: "32",
            name: "Our favourite beach",
            photos: ["8a3e9fa8-a7e1-4c94-a80b-d48751075d4b.jpg", "670edbcf-995d-46bb-94fe-b9c7af14ad88.jpg", "1ea12070-b818-4792-b411-c4e4eeba04f0.jpg"],
            text: "",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "33",
            place: "33",
            name: "Dragør",
            photos: ["689d97d0-6396-4ec6-a459-087c594a0b47.jpg", "4b527432-2265-44be-8842-7dd0a002d37a.jpg"],
            text: "",
            stars: 5,
            isPublic: true,
        },
        {
            id: "34",
            place: "34",
            name: "GeoCenter Møns Klint",
            photos: [],
            text: "",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "35",
            place: "35",
            name: "Lund",
            photos: ["e05de7da-f2ac-46c7-807e-e4ab347e2641.jpg"],
            text: "",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "36",
            place: "36",
            name: "Crabi, Ao Nang Beach",
            photos: ["55a9fb92-7fd8-457b-9c98-74c432985b83.jpg", "5913b525-18d6-4cb7-aa1f-f3455f89fb35.jpg"],
            text: "",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "37",
            place: "37",
            name: "Maya Bay",
            photos: ["4acabec9-f8bc-4d07-9ae7-bf0351c06875.jpg", "abccb51a-2958-4583-96f7-455af938d0ab.jpg"],
            text: "",
            stars: 3.5,
            isPublic: true,
        },
        {
            id: "38",
            place: "38",
            name: "Chicken Island",
            photos: ["afd3e8a6-33cd-4e80-9f4f-fae1fca40d0c.jpg"],
            text: "",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "39",
            place: "39",
            name: "Similan Islands",
            photos: ["e4baaa84-14dd-4514-90f8-230a7360799c.jpg", "7750a278-684c-4e88-b350-6b82bdb09ca2.jpg"],
            text: "",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "40",
            place: "40",
            name: "Bangkok",
            photos: ["0ba2b8de-5fa4-4a08-b8fa-c3797e0eda3b.jpg", "e2ab978f-ed96-48a9-8bce-b0e9eb324d5e.jpg"],
            text: "",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "41",
            place: "41",
            name: "Kuala Lumpur",
            photos: ["6fb81f3d-f5f6-45d2-a11f-7652f6c7d7a1.jpg", "1a33a3cf-dff1-40f7-b2ab-00e8a6519828.jpg", "b4d663a1-6b99-45ee-b3c8-4ada1f4f55ed.jpg"],
            text: "",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "42",
            place: "42",
            name: "Perhentian Islands",
            photos: ["1a33a3cf-dff1-40f7-b2ab-00e8a6519828.jpg", "ef2cc3b5-da9e-4dd7-97b3-6e832558247d.jpg", "4c4c8848-ba74-401b-bd10-a4795f9ea180.jpg"],
            text: "",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "43",
            place: "43",
            name: "Redang Island",
            photos: ["fee55032-96a2-4f4e-a19c-4cb53693abea.jpg"],
            text: "",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "44",
            place: "44",
            name: "Odense",
            photos: ["834dfb40-58fe-468a-b972-1f564ce61eb7.jpg", "81d7115b-75a8-49c2-99d2-f651ad3f08b7.jpg"],
            text: "",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "45",
            place: "45",
            name: "Kongens Have",
            photos: ["f5c9f11c-9b72-4f4b-9460-191f38b96169.jpg", "59d5a444-b01f-4268-83c7-967085e063cd.jpg"],
            text: "",
            stars: 4.5,
            isPublic: true,
        },
        {
            id: "47",
            place: "41",
            name: "Kuala Lumpur",
            photos: ["61c1ecc0-7238-401b-8718-610a90281842.jpg"],
            text: "",
            stars: 2,
            isPublic: true,
        },
        {
            id: "48",
            place: "14",
            name: "Bergen",
            photos: ["0e48bf52-74b6-4442-b15a-30c82631568c.jpg"],
            text: "",
            stars: 5,
            isPublic: true,
        },
    ],

    storyParts: [
        {
            id: "story-part-1",
            text: "We wanted to spent one day in Shamonix before trekking, we visited Aiguille du Midi, did some shopping and relaxad before tour itself.",
            photos: ["1_thai_001.jpg"],
        },
        {
            id: "story-part-2",
            text: "We chose to go with the hard part of the route. Weather was perfect and the landscape was breathtaking. Trail was not as hard as we expected.",
            photos: ["1_thai_006.jpg"],
        },
    ],

    connections: [
        {
            id: "connection-1",
            title: "First trekking day",
            start: "3",
            end: "4",
            parts: ["story-part-2"],
        },
    ],

    stories: [
        {
            id: "1",
            title: "Tour de Mont Blanc",
            text: "That is a story of our hiking vacation. Summer 2019",
            subText: "Hiking, 7 days, wine tasting 2 days",
            tags: "#hiking #europe #tmb #france",
            image: "c0ffd544-178b-45bd-b700-b1e7660ef583.jpg",
            published: "19 October 2019",
            user: "1",
            users: ["1", "2"],
            isPublic: true,
            experiences: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "1", "11", "12"],
            story: ["12", "1", "story-part-1", "2", "3", "connection-1", "4", "5", "6", "7", "8", "9", "10", "1", "11", "12"],
            placesHighlighted: ["12", "1", "7", "11"],
        },
        {
            id: "2",
            title: "Christmas in Norway",
            text: "That was a cold winter trip, we were chasing northern lights. December 2015 - January 2016",
            subText: "Relaxing city tour 7 days",
            tags: "#norway #northern #winter #northern_lights",
            image: "e933fba8-71a7-4d64-b2a3-3d7aea6727df.jpg",
            published: "23 September 2019",
            user: "1",
            isPublic: true,
            experiences: ["13", "14", "15", "16", "17", "13"],
            story: ["13", "14", "15", "16", "17", "13"],
            placesHighlighted: ["13", "14", "15", "16", "17"],
        },
        {
            id: "3",
            title: "My favourite experiences in Siberia",
            text: "I was born near one of the most amazing lakes in the world! In Siberia, Russia. ",
            tags: "#siberia #nature #mountains #intothewild #trekking",
            image: "0b5897bb-988b-41d5-a6a0-2236ece1d934.jpg",
            published: "7 October 2019",
            user: "1",
            isPublic: true,
            experiences: ["18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28"],
            story: ["18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28"],
            placesHighlighted: ["20", "25", "26"],
        },
        {
            id: "4",
            title: "My favourite experiences around Copenhagen",
            text: "Never stop exploring! After we moved to Copenhagen, we've been really enjoying exploring new experiences around the city.",
            tags: "#danmark #parks #beaches #weekend #copenhagen",
            image: "b205ca87-da7f-46a8-8651-7f19882841b5.jpg",
            published: "3 October 2019",
            user: "1",
            isPublic: true,
            experiences: ["29", "30", "31", "32", "33", "34"],
            story: ["29", "30", "31", "32", "33", "34"],
            placesHighlighted: ["29", "30", "31", "33"],
        },
        {
            id: "5",
            title: "Beach vacation in Thailand",
            text: "February, 2015",
            tags: "#beach #snorkling #Thailand",
            image: "d437f4b3-1bd1-4c7d-b9e4-19830ca1cc20.jpg",
            published: "1 August 2019",
            user: "1",
            isPublic: true,
            experiences: ["40", "36", "37", "38", "39", "40"],
            story: ["40", "36", "37", "38", "39", "40"],
            placesHighlighted: ["36", "37", "38", "39", "40"],
        },
        {
            id: "6",
            title: "Beach vacation in Malaysia",
            text: "August, 2017",
            tags: "#beach #snorkling #Malaysia",
            image: "5b59669a-1162-452f-b900-5e75f7bcbf6e.jpg",
            published: "12 September 2019",
            user: "1",
            isPublic: true,
            experiences: ["41", "42", "43"],
            story: ["41", "42", "43"],
            placesHighlighted: ["41", "42", "43"],
        },
        {
            id: "7",
            title: "How to spend weekend around Copenhagen?",
            text: "That is my list of what to do and where to go on weekend around Copenhagen",
            subText: "1 day activities around Copenhagen",
            tags: "#denmark #copenhagen #weekend",
            image: "2a9ac6df-2500-4a2e-9424-5ba9982890b3.jpg",
            published: "16 November 2019",
            user: "2",
            isPublic: true,
            experiences: ["44", "45"],
            story: ["44", "45"],
            placesHighlighted: ["44", "45"],
        },
        {
            id: "8",
            title: "My top favorite cities around the world",
            text: "That is the cities that I what to visit again and again",
            subText: "",
            tags: "#arondtheworld #see&die #travel",
            image: "883fe726-88bd-48c1-b614-edb610c4a97b.jpg",
            published: "3 August 2019",
            user: "2",
            isPublic: true,
            experiences: ["44", "47", "48"],
            story: ["44", "47", "48"],
            placesHighlighted: ["44", "47", "48"],
        },
    ],

}, {
    mapModel: new MapModel(),
    storageModel: new StorageModel(app.storage()),
});

console.log(model);

let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);
// We listen to the resize event
window.addEventListener("resize", () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
});

const IndexComponent: React.FC = () => {
    const [modal, toggle] = useToggle(true);
    return (
        <RootModelContext.Provider value={model}>
            <Routes />
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Disclaimer</ModalHeader>
                <ModalBody>
                    This page is a draft of the idea. If you have interest, rich out to <a href="mailto:olsaukh@gmail.com">olsaukh@gmail.com</a>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
            <div>

            </div>
        </RootModelContext.Provider>
    );
};

ReactDOM.render(
    <IndexComponent />,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
