import { observable } from "mobx";
import { createTransformer } from "mobx-utils";

export default class StorageModel {
    readonly FOLDERS = {
        "place": "place",
        "place_preview": "place_preview",
        "story": "story",
        "story_preview": "story_preview",
    };

    private storageRef: IStorage;

    constructor(storageRef: IStorage) {
        this.storageRef = storageRef;
    }

    getImage(folder: string, fileName: string) {
        return this.storageRef.ref(`${folder}/${fileName}`)
            .getDownloadURL()
            .catch(error => {
                console.error(error.code, error);
                return undefined;
            });
    }

    getImageAsBox(folder: string, fileName: string) {
        const result = observable.box<string | undefined>(undefined);
        this.getImage(folder, fileName)
            .then(value => {
                result.set(value);
            });
        return result;
    }

    getPlaceImage = createTransformer((fileName: string) => {
        return this.getImage(this.FOLDERS.place, fileName);
    });

    getPlacePreview = createTransformer((fileName: string) => {
        return this.getImage(this.FOLDERS.place_preview, fileName);
    });

    getStoryImage = createTransformer((fileName: string) => {
        return this.getImage(this.FOLDERS.story, fileName);
    });

    getStoryPreview = createTransformer((fileName: string) => {
        return this.getImage(this.FOLDERS.story_preview, fileName);
    });

    getPlaceImageObservable = createTransformer((fileName: string) => {
        return this.getImageAsBox(this.FOLDERS.place, fileName);
    });

    getPlacePreviewObservable = createTransformer((fileName: string) => {
        return this.getImageAsBox(this.FOLDERS.place_preview, fileName);
    });

    getStoryImageObservable = createTransformer((fileName: string) => {
        return this.getImageAsBox(this.FOLDERS.story, fileName);
    });

    getStoryPreviewObservable = createTransformer((fileName: string) => {
        return this.getImageAsBox(this.FOLDERS.story_preview, fileName);
    });

    uploadImage(folder: string, fileName: string, data: Blob, contentType: string) {
        return this.storageRef.ref(`${folder}/${fileName}`)
            .put(data, {contentType});
    }
}

export interface IStorage {
    ref(path?: string): IReference;
}

export interface IReference {
    getDownloadURL(): Promise<string>;
    delete(): Promise<void>;
    put(data: Blob | Uint8Array | ArrayBuffer, metadata?: IReferenceMetadata): Pick<Promise<any>, "then" | "catch">;
    getMetadata(): Promise<IReferenceMetadata>;
}

export interface IReferenceMetadata {
    /**
     * Served as the 'Cache-Control' header on object download.
     */
    cacheControl?: string | null;
    contentDisposition?: string | null;
    /**
     * Served as the 'Content-Encoding' header on object download.
     */
    contentEncoding?: string | null;
    /**
     * Served as the 'Content-Language' header on object download.
     */
    contentLanguage?: string | null;
    /**
     * Served as the 'Content-Type' header on object download.
     */
    contentType?: string | null;
    /**
     * Additional user-defined custom metadata.
     */
    customMetadata?: {
         [key: string]: string;
     };
}
