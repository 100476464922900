import mapboxgl from "mapbox-gl";
import { createAtom, IAtom } from "mobx";
import { types } from "mobx-state-tree";
import { throttle } from "throttle-debounce";

export const Latitude = types.refinement("Latitude", types.number, (val) => -90 <= val && val <= 90);
export const Longitude = types.refinement("Longitude", types.number, (val) => -180 <= val && val <= 180);

export default class MapModel {
    private mapObj?: mapboxgl.Map;
    private bounds?: mapboxgl.LngLatBounds;
    private boundsAtom: IAtom;
    private readonly changeHandler = throttle(16, () => {
        if (this.mapObj) {
            this.bounds = this.mapObj.getBounds();
            this.boundsAtom.reportChanged();
        }
    }, true);

    constructor() {
        this.boundsAtom = createAtom(
            "mapBounds",
            this.onMoveHandlers,
            this.offMoveHandlers,
        );
    }

    public get mapBounds() {
        this.boundsAtom.reportObserved();
        return this.bounds;
    }

    public createMap(options: mapboxgl.MapboxOptions) {
        this.disposeMap();
        this.mapObj = new mapboxgl.Map({
            ...options,
            center: [60.580743, -1.261006], // starting position [lng, lat]
            zoom: 1, // starting zoom
        });
        if (this.boundsAtom.isBeingObserved) {
            this.onMoveHandlers();
        }
        return this.mapObj;
    }

    public disposeMap() {
        if (this.mapObj) {
            this.offMoveHandlers();
            this.mapObj.remove();
        }
    }

    private onMoveHandlers = () => {
        if (this.mapObj) {
            this.changeHandler();
            this.mapObj.on("movestart", this.changeHandler);
            this.mapObj.on("move", this.changeHandler);
            this.mapObj.on("moveend", this.changeHandler);
        }
    };

    private offMoveHandlers = () => {
        if (this.mapObj) {
            this.changeHandler.cancel();
            this.bounds = undefined;
            this.mapObj.off("movestart", this.changeHandler);
            this.mapObj.off("move", this.changeHandler);
            this.mapObj.off("moveend", this.changeHandler);
        }
    };
}
