import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import useDefinedContext from "../../hooks/useDefinedContext";
import RootModelContext from "../../models/RootModel";

export interface ContactsProps extends RouteComponentProps<{id: string}> {
}

const Users: React.FC<ContactsProps> = observer((props: ContactsProps) => {
    const model = useDefinedContext(RootModelContext);
    const user = model.users.find(user => user.id === props.match.params.id);
    return (
        <>
            <div>HI! {model.user && model.user.name}
                {user ? (
                    <p>This should be a page for user #{user.id} - {user.name}</p>
                ) : (
                    <p>experience #{props.match.params.id} not found :(</p>
                )}

            </div>
            <Link to="/">Go back to main</Link>
        </>
    );
});

export default Users;
