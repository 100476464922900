import { observer } from "mobx-react";
import React from "react";
import useDefinedContext from "../../hooks/useDefinedContext";
import RootModelContext from "../../models/RootModel";
import "./storiesPreviewPanel.css";
import StoryPreviewTile from "./storyPreviewTile";
import Footer from "../Footer/Footer";

export interface NavigationPanelProps {
}

const StoriesPreviewPanel: React.FC<NavigationPanelProps> = observer((props: NavigationPanelProps) => {
    const model = useDefinedContext(RootModelContext);

    return (
        <div>
            <div className="storiesPreviewArea">
                {model.foundStories ?
                    model.foundStories.map(story => (
                        <StoryPreviewTile story={story} key={story.id} />
                    )) :
                    <h2> Nothing found :( </h2>
                }
            </div>
            <Footer/>
        </div>
    );
});

export default StoriesPreviewPanel;
