import { cast, getEnv, Instance, types } from "mobx-state-tree";
import {createContext} from "react";
import MapModel, {Latitude, Longitude} from "./MapModel";
import StorageModel from "./StorageModel";


export const Place = types.model("Place", {
    id: types.identifier,
    name: types.string,
    longitude: Longitude,
    latitude: Latitude,
});

export const Experience = types.model("Experience", {
        id: types.identifier,
        place: types.reference(Place),
        name: types.string,
        photos: types.array(types.string),
        text: types.string,
        stars: types.number,
        isPublic: types.boolean,
    })
    .views(self => ({
        get searchTerm() {
            return `${self.name}${self.text}`.toLowerCase();
        },
    }))
    .actions(self => ({
        setPhotos(photos: Array<string>) {
            self.photos = cast(photos);
        }
    }));

export const StoryPart = types.model("StoryPart", {
        id: types.identifier,
        photos: types.maybe(types.array(types.string)),
        video: types.maybe(types.string),
        text: types.maybe(types.string),
    });

export const Connection = types.model("Connection", {
        id: types.identifier,
        title: types.string,
        start: types.reference(Experience),
        end: types.reference(Experience),
        parts: types.array(types.reference(StoryPart)),
    });

export const User = types.model("User", {
        id: types.identifier,
        name: types.string,
        lastName: types.string,
        username: types.string,
        pic: types.string,
        email: types.string,
        experiences: types.array(types.reference(Experience)),
    })
    .views(self => ({
        get searchTerm() {
            return `@${self.username.toLowerCase()}`;
        },
    }));

export const Story = types.model("Story", {
        id: types.identifier,
        title: types.string,
        text: types.string,
        subText: types.maybe(types.string),
        image: types.maybe(types.string),
        experiences: types.array(types.reference(Experience)),
        story: types.array(types.reference(Experience) || types.reference(Connection) || types.reference(StoryPart)),
        placesHighlighted: types.array(types.reference(Experience)),
        published: types.string,
        user: types.reference(User),
        users: types.maybe(types.array(types.string)),
        isPublic: types.boolean,
        tags: types.maybe(types.string),
    })
    .views(self => ({
        get tagsList() {
            return self.tags ?
                self.tags.split(" ")
                    .filter(tag => tag) :
                [];
        },
        get tagsSearchTerm() {
            return self.tags ? self.tags.toLowerCase() : "";
        },
        get searchTerm() {
            return `${self.title}${self.subText}${self.experiences.map(experience => experience.name)}`.toLowerCase();
        },
    }))
    .actions(self => ({
        setImage(image: string) {
            self.image = image;
        }
    }));

export const RootModel = types.model("RootModel", {
        user: types.maybe(types.reference(User)),
        users: types.array(User),
        places: types.array(Place),
        experiences: types.array(Experience),
        connections: types.array(Connection),
        storyParts: types.array(StoryPart),
        stories: types.array(Story),
    })
    .volatile((self) => ({
        currentSearch: "",
        hoveredMapPlace: undefined as Instance<typeof Experience> | undefined,
        hoveredStory: undefined as Instance<typeof Story> | undefined,
        mapModel: getEnv(self).mapModel as MapModel,
        storageModel: getEnv(self).storageModel as StorageModel,
    }))
    .extend((self) => {
        return {
            views: {
                get foundStories() {
                    const search = self.currentSearch && self.currentSearch.toLowerCase();
                    if (search) {
                        if (search.startsWith("#")) {
                            return self.stories.filter(story => story.tagsSearchTerm.indexOf(search) >= 0);
                        } else if (search.startsWith("@")) {
                            const users = new Set(self.users.filter(user => user.searchTerm.indexOf(search) >= 0));
                            return self.stories.filter(story => users.has(story.user));
                        }
                        return self.stories.filter(story => story.searchTerm.indexOf(search) >= 0);
                    } else {
                        return self.stories;
                    }
                },
                get foundPlaces() {
                    const bounds = self.mapModel.mapBounds;
                    return bounds ?
                        self.experiences.filter(experience => {
                            const {longitude, latitude} = experience.place;
                            return bounds.getWest() <= longitude && longitude <= bounds.getEast() &&
                                bounds.getSouth() <= latitude && latitude <= bounds.getNorth();
                        }) :
                        self.experiences;
                },
                userStories(user: Instance<typeof User>) {
                    return self.stories.filter(story => story.user === user);
                },
                placeExperiences(place: Instance<typeof Place>) {
                    return self.experiences.filter(experience => experience.place === place);
                },
            },
            actions: {
                setSearch(value: string) {
                    self.currentSearch = value;
                },
                setHoveredStory(story: Instance<typeof Story> | undefined) {
                    self.hoveredStory = story;
                },
                setHoveredMapPlace(experience: Instance<typeof Experience> | undefined) {
                    self.hoveredMapPlace = experience;
                },
            },
        };
    });

const RootModelContext = createContext<Instance<typeof RootModel> | undefined>(undefined);

export default RootModelContext;
