import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React, { ChangeEvent, useCallback } from "react";
import { FaRegBell, FaRegQuestionCircle, FaSearch } from "react-icons/fa";
import { FiBookOpen, FiMapPin } from "react-icons/fi";
import { GoGear, GoHeart } from "react-icons/go";
import { Link } from "react-router-dom";
import { Collapse, DropdownItem, DropdownMenu, DropdownToggle, Input, Media, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown } from "reactstrap";
import useDefinedContext from "../../hooks/useDefinedContext";

import useToggle from "../../hooks/useToggle";
import RootModelContext, { User } from "../../models/RootModel";
import logo from "./logoBig.png";
import "./NavigationHeader.css";


export interface NavigationBarProps {
    activePage: string;
    user: Instance<typeof User> | undefined;
}

const NavigationHeader: React.FC<NavigationBarProps> = observer((props: NavigationBarProps) => {
    const rootModel = useDefinedContext(RootModelContext);
    const searchChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        rootModel.setSearch(e.target.value);
    }, [rootModel]);
    const [isOpen, toggleIsOpen] = useToggle(false);

    return (
        <Navbar expand="md" color="dark" dark className="navBar">
            <NavbarBrand to="/" tag={Link}><img src={logo} alt="Travel maps club" className="logo" /></NavbarBrand>
            <NavbarToggler onClick={toggleIsOpen} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                    <NavItem className="headerSearchRelative">
                        <FaSearch className="headerSearchIcon" />
                        <Input
                            type="text"
                            name="search"
                            value={rootModel.currentSearch}
                            onChange={searchChangeHandler}
                            id="mainSearch"
                            className="headerSearch"
                            placeholder="search by place, #tag or @user"
                        />
                    </NavItem>

                    <NavItem>
                        <NavLink tag={Link} to="/about" className="iconNav"><FaRegQuestionCircle /> </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink tag={Link} to="/about" className="iconNav"><FaRegBell /> </NavLink>
                    </NavItem>

                    {props.user ? (
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav>
                                    <Media object src={props.user.pic} className="iconImg" />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem>
                                        Hello, {props.user.name}!
                                    </DropdownItem>
                                    <DropdownItem divider />

                                    <DropdownItem>
                                        <GoGear /> Account settings
                                    </DropdownItem>

                                    <DropdownItem>
                                        <GoHeart /> Favourites
                                    </DropdownItem>

                                    <DropdownItem>
                                        <FiMapPin /> Personal travel map
                                    </DropdownItem>

                                    <DropdownItem>
                                        <FiBookOpen /> Personal travel blog
                                    </DropdownItem>

                                    <DropdownItem divider />
                                    <DropdownItem>
                                        Log out
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        ) :
                        (
                            <>
                                <NavItem>
                                    <NavLink href="">Log in</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="">Sign up</NavLink>
                                </NavItem>
                            </>
                        )}
                </Nav>
            </Collapse>
        </Navbar>
    );
});

export default NavigationHeader;
