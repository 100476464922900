import { observer } from "mobx-react";
import React, { useCallback, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { RouteComponentProps } from "react-router";
import MapView from "../../components/mapView/MapView";
import NavigationHeader from "../../components/navHeader/NavigationHeader";
import StoriesPreviewPanel from "../../components/StoryPreview/storiesPreviewPanel";
import useDefinedContext from "../../hooks/useDefinedContext";
import useMediaQuery from "../../hooks/useMediaQuery";
import RootModelContext from "../../models/RootModel";
import "./Main.css";

export interface MainProps extends RouteComponentProps<{}> {

}

const Main: React.FC<MainProps> = observer((props: MainProps) => {
    const model = useDefinedContext(RootModelContext);

    const isMobile = useMediaQuery("(max-width: 768px)");
    const [storiesPanelStateValue, setStoriesPanelState] = useState<"mainPanelClosed" | "mainPanelOpen" | "mainPanelWideOpen" | undefined>(undefined);
    const storiesPanelState = storiesPanelStateValue === undefined ?
        isMobile ? "mainPanelClosed" : "mainPanelOpen" :
        storiesPanelStateValue === "mainPanelOpen" && isMobile ? "mainPanelWideOpen" : storiesPanelStateValue;
    const openHandler = useCallback(() => {
        setStoriesPanelState(curr => curr === "mainPanelClosed" ? "mainPanelOpen" : "mainPanelWideOpen");
        setTimeout(() => {
            window.dispatchEvent(new CustomEvent("resize"));
        }, 0);
    }, [setStoriesPanelState]);
    const closeHandler = useCallback(() => {
        setStoriesPanelState(curr => curr === "mainPanelWideOpen" ? "mainPanelOpen" : "mainPanelClosed");
        setTimeout(() => {
            window.dispatchEvent(new CustomEvent("resize"));
        }, 0);
    }, [setStoriesPanelState]);

    return (
        <main className={"main " + storiesPanelState}>
            <header className="header">
                <NavigationHeader activePage="main" user={model.user} />
            </header>

            <div className="storiesPreviewPanel">
                <div className="controlNavigation">

                    <div className="controlNavigationIcon">
                        <FaChevronLeft onClick={closeHandler} />
                        <FaChevronRight onClick={openHandler} />
                    </div>
                </div>

                <StoriesPreviewPanel />
            </div>

            <div className="mapContainer">
                <MapView />
            </div>
        </main>
    );
});

export default Main;
