import classNames from "classnames";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React, { useCallback } from "react";
import { FaBookOpen, FaMapMarker, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";

import { Button, Card, CardBody, CardFooter, CardImg, CardSubtitle, CardText, CardTitle, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import useDefinedContext from "../../hooks/useDefinedContext";
import RootModelContext, { Story } from "../../models/RootModel";
import "./storyPreviewTile.css";

export interface ArticlePreviewTileProps {
    story: Instance<typeof Story>;
}

const StoryPreviewTile: React.FC<ArticlePreviewTileProps> = observer((props: ArticlePreviewTileProps) => {
    const story = props.story;
    const rootModel = useDefinedContext(RootModelContext);
    const hovered = story === rootModel.hoveredStory ||
        (rootModel.hoveredMapPlace && story.experiences.some(experience => experience === rootModel.hoveredMapPlace));
    const hoverHandler = useCallback(() => rootModel.setHoveredStory(story), [rootModel, story]);
    const unhoverHandler = useCallback(() => rootModel.setHoveredStory(undefined), [rootModel]);
    const tagClickHandler = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        const tag = (e.target as HTMLAnchorElement).getAttribute("data-tag");
        if (tag) {
            rootModel.setSearch(tag);
        }
    }, [rootModel]);
    const storyImage = (story.image && rootModel.storageModel.getStoryPreviewObservable(story.image).get()) || "";
    return (
        <Card
            className={classNames("article-preview", hovered && "article-preview-hovered")}
            onMouseEnter={hoverHandler}
            onMouseLeave={unhoverHandler}
        >
            <div className="button-block">
                <Button outline={true} className="transparent-white" color="secondary"><FaMapMarker /> Show on map</Button>
                <Button outline={true} className="transparent-white" color="secondary"><FaBookOpen /> Read story</Button>
            </div>
            <CardImg top width="100%" src={storyImage} alt={story.title} />
            <CardBody>
                <CardTitle><Link to={`/stories/${story.id}`}>{story.title}</Link></CardTitle>
                {story.subText ? <CardSubtitle>{story.subText}</CardSubtitle> : ""}
                <CardText>
                    by <a href="#">@{story.user.username}</a> | {story.published}
                </CardText>
            </CardBody>
            <CardFooter>
                {story.placesHighlighted.map((experience, index) => (
                    <UncontrolledDropdown id={`/experiences/${experience.id}`} size="sm" key={index}>
                        <DropdownToggle caret>
                            <FaMapMarker />{experience.name}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem> <FaMapMarker /> Show on map</DropdownItem>
                            <DropdownItem> <FaBookOpen /> Open in story</DropdownItem>
                            <DropdownItem> <FaSearch /> Search stories here</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                ))}
                {story.tagsList.map((tag, index) => (
                    <React.Fragment key={index}>
                        <a onClick={tagClickHandler} href="#" data-tag={tag}>{tag}</a>{" "}
                    </React.Fragment>
                ))}
            </CardFooter>
        </Card>
    );
});

export default StoryPreviewTile;
