import React from "react";

interface Props {
}

const Footer: React.FC<Props> = () => {
    return null;
    // return (
    //     <div className={classNames(css.footerContainer, false && css.footerExpanded)}>
    //         {footerExpanded && <h2>Some footer text</h2>}
    //         <div>travelmaps.club  <FaRegCopyright/> All rights reserved. </div>
    //     </div>
    // );
};

export default Footer;
