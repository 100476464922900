import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import useDefinedContext from "../../hooks/useDefinedContext";
import RootModelContext from "../../models/RootModel";

export interface ContactsProps extends RouteComponentProps<{id: string}> {
}

const Stories: React.FC<ContactsProps> = observer((props: ContactsProps) => {
    const model = useDefinedContext(RootModelContext);
    const story = model.stories.find(story => story.id === props.match.params.id);
    return (
        <>
            <div>HI! {model.user && model.user.name}
                {story ? (
                    <p>This should be a page for experience #{story.id} - {story.title}</p>
                ) : (
                    <p>experience #{props.match.params.id} not found :(</p>
                )}

            </div>
            <Link to="/">Go back to main</Link>
        </>
    );
});

export default Stories;
